<template>
    <div class="contact_info_box">
        <div style="padding-bottom:16px;height: 390px;">
            <p class="info">
                <span>交付订单编号</span>
                {{ order.orderPlanNo ? order.orderPlanNo : '- -' }}
            </p>
            <p class="info">
                <span>下单名称</span>
                {{ order.customerName ? order.customerName : '- -' }}
            </p>
            
            <p class="info">
                
                <span>支付日期 </span>
                <el-date-picker
                    v-model="payTime"
                    style="width: 240px;height: 50px;"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="date"
                    placeholder="请选择支付日期"
                >
                </el-date-picker>
            
                
            </p>
        </div>
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取 消</el-button>
            <el-button type="primary" size="mini" @click="onSubmit" round>保 存</el-button>
        </div>
    </div>
</template>

<script>
import { orderPlanUpdate } from '@/api/order/order.js';
export default {
    data() {
        return {
            order: {},
            payTime:"",
            
        };
    },
    methods: {
        getData(row) {
            console.log(row);
            this.order = row;
            this.payTime = row.payTime;
            
        },
        onClose() {
            this.$emit('close');
            this.status = '';
        },
       
        onSubmit() {
            let data = {
                param: {
                    id: this.order.id,
                    payTime:this.payTime,
                },
            };
            orderPlanUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$emit('close');
                    this.status = '';
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contact_info_box {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    .title {
        line-height: 1.2;
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        color: #333;
        padding-left: 10px;
        > i {
            color: #2370eb;
            font-size: 18px;
        }
        button {
            background: rgb(255, 255, 255);
            color: rgb(35, 112, 235);
            border-color: rgb(35, 112, 235);
            padding: 5px 10px;
            float: right;
            margin-right: 20px;
        }
    }
    .el-form {
        margin-left: 25px;
        /deep/ .el-form-item__label {
            font-weight: 600 !important;
        }
    }
    .info {
        font-size: 14px;
        color: #333;
        padding-left: 107px;
        box-sizing: border-box;
        margin-top: 15px;
        span {
            display: inline-block;
            width: 90px;
        }
    }
}
.footer_btn {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    background-color: #fff;
    .el-button--primary {
        background-color: #2370eb;
        border-color: #2370eb;
    }
    button:last-child {
        margin-right: 30px;
    }
}
</style>
